<template>
  <div class="law">
    <!-- 头部 -->
    <header class="law-header">
      <div class="keyword-box">
        <span class="keyword-span">请输入关键词:</span>
        <el-input v-model="input_keyword" placeholder="请输入内容"></el-input>
      </div>
      <!-- <div class="block">
        <span class="demonstration">默认</span>
        <el-date-picker
          v-model="input_start"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div> -->
      <div class="block">
        <span class="demonstration">发布时间:</span>
        <el-date-picker v-model="input_end"
        prefix-icon='daicon' type="date" placeholder="选择日期">
        </el-date-picker>
      </div>
      <!-- 选择发布状态 -->
      <div class="block">
        <span class="demonstration">发布状态</span>
        <el-select v-model="select" slot="prepend" placeholder="请选择">
          <el-option label="全部" value="0"></el-option>
          <el-option label="已发布" value="1"></el-option>
          <el-option label="未发布" value="2"></el-option>
        </el-select>
      </div>
      <el-button type="primary" size="small" class="serachbtn">查询</el-button>
    </header>
    <!-- 顶部 -->

    <!-- 表格内容 -->
    <section class="nav-table">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index"  center width="50"> </el-table-column>
        <el-table-column property="title" label="政策标题" width="500">
        </el-table-column>
        <el-table-column property="titletype" label="政策类型" width="250">
        </el-table-column>
        <el-table-column property="from" label="来源" width="150"> </el-table-column>
        <el-table-column property="condition" label="发布状态" width="150"> </el-table-column>
        <el-table-column property="changeT" label="修改时间"> </el-table-column>

        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="primary" size="small"
              >查看</el-button>
            <el-dialog
              class="info-dialog"
              :append-to-body= true
              :visible.sync="dialogVisible"
              width="45%"
              :title="`${dialogtext.title}`"
              :before-close="handleClose">
              <div style="width:100%;height: 1px;background: #167fff;margin-bottom: 20px;margin-top:9px"></div>
              <div class="newsbox">


                  <h3 class="newsboxtitle">{{dialogtext.title}}</h3>
                  <br/>
                  <span class="newsboxtitle-b">{{dialogtext.from}} {{dialogtext.changeT}}</span>
                  <span>
                    {{dialogtext.content}}
                  </span>
                  <span>
                    {{dialogtext.content1}}
                  </span>
                  <span>
                    {{dialogtext.content2}}
                  </span>
                  <span>
                    {{dialogtext.content3}}
                  </span>
                  <span>
                    {{dialogtext.content3}}
                  </span>
                  <span>
                    {{dialogtext.content4}}
                  </span>
                  <span>
                    {{dialogtext.content5}}
                  </span>
                  <span>
                    {{dialogtext.content6}}
                  </span>

              </div>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
              </span>
            </el-dialog>
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>


        </el-table-column>
      </el-table>
    </section>
  </div>
</template>

<script>
export default {
  name: "law",
  data() {
    return {
      dialogtext:'',
      dialogVisible:false,
      input_keyword: "",
      input_start: "",
      input_end: "",
      // 选择
      select: "",
      //table
      tableData:[],
      tableDatalist: [
        {
          title:'中华人民共和国海岛保护法',
          titletype:'具体政策',
          from:'威海市海洋与渔业局',
          condition:'已发布',
          changeT:'2011-05-14',
          detail:'中华人民共和国海岛保护法',
          content:'第一条 为了保护海岛及其周边海域生态系统，合理开发利用海岛自然资源，维护国家海洋权益，促进经济社会可持续发展，制定本法。',
          content2:'第二条 从事中华人民共和国所属海岛的保护、开发利用及相关管理活动，适用本法。',
          content3:'本法所称海岛，是指四面环海水并在高潮时高于水面的自然形成的陆地区域，包括有居民海岛和无居民海岛。',
          content4:'本法所称海岛保护，是指海岛及其周边海域生态系统保护，无居民海岛自然资源保护和特殊用途海岛保护。',
          content5:'第三条 国家对海岛实行科学规划、保护优先、合理开发、永续利用的原则。',
          content6:'国务院和沿海地方各级人民政府应当将海岛保护和合理开发利用纳入国民经济和社会发展规划，采取有效措施，加强对海岛的保护和管理，防止海岛及其周边海域生态系统遭受破坏。',
          content7:'第四条 无居民海岛属于国家所有，国务院代表国家行使无居民海岛所有权。',
          content8:'第五条 国务院海洋主管部门和国务院其他有关部门依照法律和国务院规定的职责分工，负责全国有居民海岛及其周边海域生态保护工作。沿海县级以上地方人民政府海洋主管部门和其他有关部门按照各自的职责，负责本行政区域内有居民海岛及其周边海域生态保护工作。',
          content9:'　国务院海洋主管部门负责全国无居民海岛保护和开发利用的管理工作。沿海县级以上地方人民政府海洋主管部门负责本行政区域内无居民海岛保护和开发利用管理的有关工作。',
          content10:'第六条 海岛的名称，由国家地名管理机构和国务院海洋主管部门按照国务院有关规定确定和发布。',
          content11:'沿海县级以上地方人民政府应当按照国家规定，在需要设置海岛名称标志的海岛设置海岛名称标志。'
        },
        {
          title:'海岛名称管理的管辖范围',
          titletype:'基本政策',
          from:'威海市海洋与渔业局',
          condition:'已发布',
          changeT:'2014-12-29',
          detail:'海岛名称管理的管辖范围是什么？',
          content:'国家海洋局《海岛名称管理办法》明确：海岛名称管理的管辖范围是中华人民共和国所属海岛，但有乡级以上人民政府驻地的海岛除外。'
        },
        {
          title:'海岛名称管理工作的主要内容',
          titletype:'基本政策',
          from:'威海市海洋与渔业局',
          condition:'已发布',
          changeT:'2014-12-29',
          detail:'海岛名称管理工作的主要内容是什么？',
          content:'国家海洋局《海岛名称管理办法》明确：海岛名称管理包括海岛命名、更名、名称注销、名称登记、名称发布与使用、名称标志设置等有关工作。国家海洋局负责对海岛命名、更名和名称注销予以登记。领海基点海岛及其他涉及海洋权益、国防、外交事务海岛需要命名、更名的，国家海洋局报国务院批准后，予以登记。国家海洋局根据海岛名称登记情况，发布海岛标准名录。沿海县级以上人民政府海洋主管部门负责组织编印管辖区域内海岛标准名称出版物，及时向社会提供和推广使用海岛标准名称。任何单位和个人使用海岛名称时，必须使用国家公布的标准名称。各级海洋主管部门应当加强对海岛名称使用的监督检查，对使用不规范海岛名称的行为，应当予以纠正。'
        },
        {
          title:'海岛名称标准化处理',
          titletype:'基本政策',
          from:'威海市海洋与渔业局',
          condition:'已发布',
          changeT:'2014-12-29',
          detail:'海岛名称标准化处理是什么？',
          content:'2011年，国家海洋局制定了《关于海岛名称标准化处理的意见》，明确海岛名称标准化处理的任务包括：１.对尚未命名的海岛地理实体进行命名；２.对已有海岛名称的地理实体进行认定或更名；３.对已灭失的海岛地理实体的名称进行注销；４.编制标准化处理成果。'
        },
        {
          title:'废弃物海洋倾倒许可证核发',
          titletype:'基本政策',
          from:'威海市海洋与渔业局',
          condition:'已发布',
          changeT:'2014-09-14          ',
          detail:'废弃物海洋倾倒许可证核发',
          content:'单项工程总倾倒量在100万方（含）以下或港口年度维护性疏浚量100万方（含）以下的疏浚物，渔船，渔业加工废料，惰性无机地质材料，天然有机废料，人体骨灰。'
        },
      ]
    };
  },
  watch:{
    input_keyword:function(val){
      if (val == '') {
        this.tableData = this.tableDatalist
      }else{
        this.tableData = this.searchName(val)
      }
    }
  },
  // computed(){

  // },
  mounted() {
    this.tableData = this.tableDatalist
  },
  methods: {
    searchName(keywords){
      return this.tableDatalist.filter(item=>{
        if (item.title.includes(keywords)) {
          return item

        }
      })
    },
    handleClick(row) {
      this.dialogVisible = true
      this.dialogtext = row
    },
    handleClose(done) {
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {});
    },

  }
};
</script>

<style scoped lang='scss'>
.law {
  height: 100%;
  width: 100%;
  position: relative;
  padding-top:10px;
}
.law-header {
  height: 60px;
  width: 100%;
  /* background: #dad5d5; */
  display: flex;
  justify-content: flex-start;
  align-items: center;

}
.nav-table {
  height: 100%;
  width: 100%;
  background: #ececec;
  margin-top: 10px;
}
.nav {
  height: 40px;
  width: 100%;
  /* background: #e7e6e6; */
  display: flex;
  justify-content: flex-end;
}
.nav .el-button {
  margin-right: 15px;
}
.keyword-box {
  /* display: flex; */
  padding-right: 33px;
  font-size: 13px;
  justify-content: center;
}
.keyword-box ::v-deep .el-input__inner {
  height: 34px;
  width: 200px;
}
.keyword-box ::v-deep .el-input {
  height: 34px;
  width: 200px;
}
.keyword-span {
  padding-right: 14px;
  height: 40px;
  line-height: 40px;
  width: 151px;
  padding-left: 22px;
}
.block {
  padding-left: 15px;
  font-size: 13px;
  line-height: 34px;

}
.block ::v-deep .el-input__inner{
  height: 34px;
  line-height: 34px!important;
}
.block ::v-deep .el-date-editor--date >>> .el-input__prefix{
  line-height: 34px!important;
}

.demonstration {
  padding-right: 10px;
}
/* 选择 */
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.newsbox{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.serachbtn{
  margin-left: 8px;
}
.newsbox span{
  text-align: start;
  text-indent: 32px;
  padding-bottom: 10px;
}
.newsboxtitle{
  width: 100%;
  text-align: center;
}
.newsboxtitle-b{
  padding-bottom: 10px;
  width: 100%;
  text-align: center !important;
}
 .info-dialog ::v-deep .el-dialog__body{
  padding: 0 10px !important
}
.info-dialog ::v-deep .el-dialog__header{
  padding-bottom: 0;
}
</style>
